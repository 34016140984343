import React, { useState } from 'react';
import { Typography, Button, Switch } from 'antd';
import { ChromeOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const { Title, Paragraph } = Typography;

const ChromeExtensionPage = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const navigate = useNavigate();

  const containerStyles = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
    padding: '20px',
    background: isDarkMode ? '#1a1a1a' : 'linear-gradient(to right, #c4dcf5, #a1c4fd)',
    transition: 'background 0.3s ease',
  };

  const titleStyles = {
    color: isDarkMode ? 'white' : 'black',
    fontSize: '3rem',
    marginBottom: '20px',
  };

  const subtitleStyles = {
    color: isDarkMode ? 'white' : 'black',
    fontSize: '1.5rem',
    maxWidth: '600px',
    marginBottom: '30px',
  };

  

  const buttonStyles = {
    backgroundColor: isDarkMode ? 'orange' : undefined,
    borderColor: isDarkMode ? 'orange' : undefined,
  };

  return (
    <div style={containerStyles}>
      <Switch
        checked={isDarkMode}
        onChange={setIsDarkMode}
        checkedChildren="🌙"
        unCheckedChildren="☀️"
        style={{ position: 'absolute', top: '1rem', right: '1rem', transform: 'scale(1.5)' }}
      />
      <Button
        type="default"
        icon={<ArrowLeftOutlined />}
        onClick={() => navigate(-1)}
        style={{ position: 'absolute', top: '1rem', left: '1rem', transform: 'scale(1.5)', marginLeft: '1rem' }}
      />
      <ChromeOutlined style={{ fontSize: '100px', color: isDarkMode ? 'white' : 'black', marginBottom: '20px' }} />
      <Title style={titleStyles}>
        Chrome Extension Available Now!
      </Title>
      <Paragraph style={subtitleStyles}>
        Understand privacy policies with a single click. Download our extension from the Chrome Web Store.
      </Paragraph>
      <Button 
        size="large" 
        type="primary" 
        href="https://chromewebstore.google.com/detail/polisee-stay-in-good-term/gbgioimofljipoffnogaclfhpgemookk?authuser=0&hl=en"
        target="_blank"
        style={buttonStyles}
      >
        Get the Extension
      </Button>
    </div>
  );
};

export default ChromeExtensionPage;