import React, { useState } from 'react';
import { Form, Input, Button, message, Typography, Card, Switch, Radio } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

const { Title } = Typography;
const { TextArea } = Input;

const FeedbackPage = () => {
    const [form] = Form.useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(false);
    const navigate = useNavigate();

    const onFinish = async (values) => {
        setIsSubmitting(true);
        try {
            const response = await fetch('https://email.delayel06.workers.dev', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: values.likedSite,
                    feedback: values.feedback,
                }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            if (data.success) {
                message.success('Feedback sent successfully!');
                form.resetFields();
            } else {
                message.error(data.message || 'Failed to send feedback. Please try again.');
            }
        } catch (error) {
            console.error('Feedback submission error:', error);
            message.error('An error occurred. Please try again.');
        }
        setIsSubmitting(false);
    };

    const themeColors = {
        light: {
            primary: '#1890ff',
            background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
            cardBg: 'white',
            text: '#000000',
            inputBg: '#ffffff',
            inputText: '#000000',
            buttonBg: '#1890ff',
            buttonText: 'white',
        },
        dark: {
            primary: '#177ddc',
            background: 'linear-gradient(135deg, #1a1a1a 0%, #2d2d2d 100%)',
            cardBg: '#2d2d2d',
            text: '#ffffff',
            inputBg: '#1f1f1f',
            inputText: '#ffffff',
            buttonBg: '#177ddc',
            buttonText: 'white',
        }
    };

    const currentTheme = isDarkMode ? themeColors.dark : themeColors.light;

    const styles = {
        page: {
            minHeight: '100vh',
            width: '100%',
            background: currentTheme.background,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px',
            transition: 'all 0.3s ease',
        },
        card: {
            width: '100%',
            maxWidth: '500px',
            background: currentTheme.cardBg,
            borderRadius: '15px',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
            transition: 'all 0.3s ease',
        },
        title: {
            color: currentTheme.text,
            fontSize: '24px',
            textAlign: 'center',
            marginBottom: '24px',
        },
        radioGroup: {
            marginBottom: '20px',
        },
        radio: {
            color: currentTheme.text,
            marginRight: '24px',
        },
        textArea: {
            backgroundColor: currentTheme.inputBg,
            color: currentTheme.inputText,
            border: '1px solid ' + currentTheme.primary,
            borderRadius: '8px',
            padding: '12px',
            marginBottom: '20px',
            '&::placeholder': {
                color: isDarkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
            },
        },
        button: {
            backgroundColor: currentTheme.buttonBg,
            color: currentTheme.buttonText,
            height: '40px',
            fontSize: '16px',
            borderRadius: '8px',
            border: 'none',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            '&:hover': {
                opacity: 0.9,
            },
        },
    };

    return (
        <div style={styles.page}>
            <Switch
                checked={isDarkMode}
                onChange={setIsDarkMode}
                checkedChildren="🌙"
                unCheckedChildren="☀️"
                style={{ position: 'absolute', top: '20px', right: '20px' }}
            />
            <Button
                icon={<ArrowLeftOutlined />}
                onClick={() => navigate(-1)}
                style={{ position: 'absolute', top: '20px', left: '20px' }}
            >
                Back
            </Button>
            
            <Card style={styles.card}>
                <Title level={2} style={styles.title}>
                    How was your experience?
                </Title>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="likedSite"
                        rules={[{ required: true, message: 'Please select an option' }]}
                    >
                        <Radio.Group style={styles.radioGroup}>
                            <Radio value="yes" style={styles.radio}>Excellent! 😊</Radio>
                            <Radio value="no" style={styles.radio}>Could be better 😕</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        name="feedback"
                        rules={[{ required: true, message: 'Please share your thoughts with us' }]}
                    >
                        <TextArea
                            rows={4}
                            placeholder="Tell us more about your experience..."
                            style={styles.textArea}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={isSubmitting}
                            block
                            style={styles.button}
                        >
                            Submit Feedback
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
};

export default FeedbackPage;